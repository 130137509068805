@import url("https://fonts.googleapis.com/css?family=Jura:400");
* {
  font-family: Jura, Arial;
}
body {
  padding-top: 5px;
  padding-bottom: 40px;
  background-color: #FFFFFF;
}

.btn-block {
  width: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.label-span {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #c7c7c7;
  color: rgba(var(--edc, 199, 199, 199), 1);
  font-size: 14px;
  left: 0;
  margin: 3px 9px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: -webkit-transform ease-out .1s;
  transition: -webkit-transform ease-out .1s;
  transition: transform ease-out .1s;
  transition: transform ease-out .1s, -webkit-transform ease-out .1s;
  white-space: nowrap;
}

.email-input {
  border: 0;
  padding: 4px 9px;
  width: 100%;
  outline: 0 !important;
  background: rgba(var(--b3f, 250, 250, 250), 1);
  border-radius: 6px;
  color: rgba(var(--i1d, 38, 38, 38), 1);
  -webkit-box-flex: 1;
  flex-grow: 1;
  font-size: 14px;
  line-height: 30px;
  margin: 0;
  overflow: visible;
  border-radius: 6px;
  border: 1px solid rgba(var(--b38, 219, 219, 219), 1);
}
.loginContainer {
  display: grid;
  place-items: center;
  margin-top: 50px;
}
.login-box {
  background: rgb(164, 165, 208);
  /* background: rgba(190, 182, 182, 0.562); */
  width: 400px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 32px 64px rgb(0 0 0 / 20%);
}

.submitBtn {
  margin-top: 20px;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: bold;
}

.submitBtn:hover {
  background: rgb(164, 165, 208);
  color: white;
}
